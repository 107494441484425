import { getFormatedAmount } from "./getFormatedAmount";


export const getSuspensionEmailText = (payments, customer) => {
     
     
    return `
        Estimado socio – cliente  ${customer}
        <br>
        <br>
        Le informamos que, debido a que cuenta con factura(s) vencida(s) del servicio de practicantes el estatus de su empresa ha cambiado a “Suspendida”, deteniendo la siguiente dispersión programada. 

        Una vez que se reciba el pago cambiará a estatus “Liberada”, recibirá una notificación oficial por este medio y con ello se programará la dispersión. 
        <br>
        <br>
        Es importante mencionar que las dispersiones extemporáneas se programan únicamente los miércoles y viernes, condicionado a que el pago se refleje antes de las 8:30am, en caso contrario se dispersará hasta el siguiente miércoles o viernes inmediato. 
        <br>
        <br>
        En caso de recibir el pago antes de la fecha regular programada de dispersión no habrá afectación en la beca de los practicantes. 
        <br>
        <br>
        Cualquier duda favor de contactar al ejecutivo de cuentas por cobrar.
        <br>
        <br>
        Atentamente: Tesorería CAINTRA
        <br>
        <br>
        ${generatePaymentsTable(payments)}
        <br>
        <br>
    `
}

const generatePaymentsTable = (payments) => {
    let table = '';

    if(payments.length){
        table = `
            <table>
                <thead>
                    <tr>
                        <th width='350px'>Fechas de periodo</th>
                        <th width='200px'>Número de factura</th>
                        <th width='100px'>Timbrada</th>
                        <th width='200px'>Total de factura</th>
                    </tr>
                </thead>

                <tbody>
                    ${generateBody(payments)}
                </tbody>
            </table>
        `
    }   
    return table;
}

const generateBody = (payments) => {
    let tableBody = '';
   
    for(let payment of payments){
        try{

            tableBody = tableBody + `                    
                <tr> 
                    <td> 
                        ${payment.periodDates}
                    </td> 
                    <td> 
                        ${payment.invoiceNumber ? payment.invoiceNumber : "No disponible"}
                    </td>
                    <td> 
                        ${payment.isStamped ? 'Sí' : 'No'}
                    </td>
                    <td> 
                        ${getFormatedAmount(payment.invoiceAmount)}
                    </td>
                  
                <tr>`;

        }catch(err){
            console.log(err)
        }
      
    }
    return tableBody;
}