import React, {useEffect, useState, useContext} from 'react';
import ListApprovedInterns from './components/ListApprovedInterns.jsx';
import {Form, Button} from 'react-bootstrap';
import { faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import RollBackPrefacture from './components/RollBackPrefacture';
import Methods from '../../utils/https';
import {UserContext} from '../../context/UserContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApprovePrefacture from './components/ApprovePrefacture.jsx';
import './Prefacture.css';
import UpdateClabe from './components/UpdateClabe.jsx';
import {StatusContpaq} from './components/StatusContpaq.jsx';
import {CancelContpaq} from './components/CancelContpaq.jsx';
import {UploadManually} from './components/UploadManually.jsx';
import Peview from './components/Preview.jsx';
import PreviewMD from './components/PreviewMD.jsx';
import VerifyInternDocs from '../Interns/components/VerifyInternDocs';
import UpdateRenewContract from '../Interns/components/UpdateRenewContract.jsx';
import LoadingModal from './components/LoadingModal.jsx';
import { DropdownList } from "react-widgets";
import ReactPaginate from "react-paginate";
import { notify } from '../../utils/notify';
import { BiCheckShield } from 'react-icons/bi';
import { AiOutlineRollback } from 'react-icons/ai';
import { RiSearch2Line } from 'react-icons/ri';
import videoPlay from '../../../src/assets/IconsCaintra/videoPlay.svg';
import { VideoPlayer } from './components/VideoPlayer.jsx';
import moment from 'moment-timezone';
const axios = require("axios");
const CURRENTURL = process.env.REACT_APP_SOCKET_URL;



export default function Prefacture () {
    const [currentTab, setCurrentTab] = useState("prefactureList");
    const [currentInternId, setCurrentInternId] = useState(undefined);
    const [interns, setInterns] = useState([]);
    const updatedFromPrefacture = true;
    const context = useContext(UserContext);
    const id_company = context.user.company._id;
    const [isLoading, setIsLoading] = useState(true);
    const [approvedInterns, setApprovedInterns] = useState([]);
    const [allApprovedInterns, setAllApprovedInterns] = useState([]);
    const [updateList, setUpdateList] = useState(false);
    const [period, setPeriod] = useState([]);
    const [isFirstPeriod, setIsFirstPeriod] = useState(false);
    const [specificMonth , setSpecificMonth] = useState(undefined);
    const [specificYear, setSpecificYear] = useState(undefined);
    const [firstPeriod, setFirstPeriod] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1), 
        new Date(new Date().getFullYear(), new Date().getMonth(), 15)
    ]);
    const [isApproved, setIsApproved] = useState(false);
    const [showApprovePrefecture, setShowApprovePrefecture] = useState(false);
    const [showRollBackPrefacture, setShowRollBackPrefacture] = useState(false);
    const [currentLimit, setCurrentLimit] = useState(300);
    const [currentSearch, setCurrentSearch] = useState(undefined);
    const [searchQuery, setSearchQuery] = useState("");
    const [prefactureId, setPrefactureId] = useState(null);
    const [isMonthly, setIsMonthly] = useState(false);
    const [isHistoricalSearch, setIsHistoricalSearch] = useState(false);
    const [historicalInterns, setHistoricalInterns] = useState([]);
    const [isSending, setIsSending] = useState(false);
    const [prefactureData, setPrefactureData] = useState({
        totalCost: 0,
        administrativeExpense: 0,
        totalAdministrativeExpense: 0,
        subtotalNoInsurance: 0,
        insuranceCompanyData: 0,
        debitCompany: 0,
        subtotalInsurance: 0,
        creditCompany: 0,
        subtotalCredits: 0,
        _total: 0,
        iva: 0
    });
    let periodo = context.user.company.payment_period;
    let fechaLimite = periodo !=="Catorcenal" ? "2024-05-15" : "2024-05-09";
    let tmp = period[1] ? moment(period[1]).format("YYYY-MM-DD") :"2024-05-15" ;
    let fechaCorte =  tmp!==undefined ? tmp : "2024-05-15";
    let fecha2 = fechaCorte;
    let fecha1 = fechaLimite;

    const [subPeriods, setSubPeriods] = useState(undefined);
    const [previousPeriods, setPreviousPeriods] = useState(undefined);
    const [allPeriodsAux, setAllPeriodsAux] = useState({ 
        isFirstSubPeriod: true,
        isSecondSubPeriod: true,
        isFirstPrevPeriod: true,
        isSecondPrevPeriod: true
    })
    const {isFirstSubPeriod, isSecondSubPeriod, isFirstPrevPeriod, isSecondPrevPeriod} = allPeriodsAux;
    const [isPrefactureDetail, setIsPrefactureDetail] = useState(false); 
    const [secondPeriodNoInterns, setSecondPeriodNoInterns] = useState(false);
    const [prefactureInterns, setPrefactureInterns] = useState(undefined);
    const [setData, setSetData] = useState(true);
    const [isEditable, setIsEditable] = useState(true);
    const [payDay, setPayDay] = useState(undefined);
    const [payDayLimit, setPayDayLimit] = useState(moment().format('DD/MM/YYY'));
    const [actualPayDay, setactualPayDay] = useState( new Date());
    const [actualPayDayLimit, setActualPayDayLimit] = useState(moment().format('DD/MM/YYY'));
    const isCaintraUser = context.user.data.isCaintraUser;
    const [consolidationValidations, setConsolidationValidations] = useState({
        isFirstQMPeriod: false,
        isSecondQMPeriod: false,
        isQMCPrefacture: false,
        isFirstConsolidationPeriod: false,
        isSecondConsolidationPeriod: false,
        forceApprovePeriod: false,
        requiereFirstPeriodApproved: true
    });
    let { 
        isFirstQMPeriod,
        isSecondQMPeriod,
        isFirstConsolidationPeriod,
        isSecondConsolidationPeriod 
    } = consolidationValidations;
    const [previusQMPrefacture, setPreviusQMPrefacture] = useState(undefined);
    const [previusQMPrefactureDetails, setPreviusQMPrefactureDetails] = useState(undefined);
    const [isConsolidationPrefacture, setIsConsolidationPrefacture] = useState(false);
    const [allPrefactureQMInterns, setAllPrefactureQMInterns] = useState([]);
    const [canRollBackFirstQMPeriod, setCanRollBackFirstQMPeriod] = useState(true);
    const [lastPeriod, setLastPeriod] = useState(undefined);
    const [company, setCompany] = useState(undefined);
    const [isLoadingPrefacture, setIsLoadingPrefacture] = useState(false);
    const [contractToUpdateRenew, setContractToUpdateRenew] = useState({});
    const [contractToUpdateZipCode, setContractToUpdateZipCode] = useState('');
    const currentCompany = context.user ? context.user.company.business_name : "";
    const [showUpdateUser, setShowUpdateUser] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewMD, setShowPreviewMD] = useState(false);

    const [uploadManually, setUploadManually] = useState(false);
    const [verifyPreview, setVerifyPreview] = useState(false);
    const [verifyPreviewMD, setVerifyPreviewMD] = useState(false);

    const [showStatusContpaq, setShowStatusContpaq] = useState(false);
    const [prefactureDetailID, setPrefactureDetailID] = useState(undefined);
    const [internToUpdate, setInternToUpdate] = useState(undefined);
    const [updateInternList, setUpdateInternList] = useState(false);
    const [sendPrefactureAux, setSendPrefactureAux] = useState({
        alreadySend: false,
        showSendPrefacture: false,
        showPurchaseOrderModal: false,
        prefactureUrl: undefined,
        prefactureUrlXml: undefined,
        canRollBackByStatus: false
    })
    const {alreadySend, prefactureUrl, prefactureUrlXml, canRollBackByStatus} = sendPrefactureAux;
    const [prefacturePaymentsAux, setPrefacturePaymentsAux] = useState({
        insuranceTotal: [],
        retroactivePayments: []
    })
    const [totalDual, setTotalDual] = useState(0);
    const [showCancel, setShowCancel] = useState(false);
    const [isCanceled, setIsCanceled] = useState(false);
    const [documentsNumber, setDocumentsNumber] = useState(1);
    const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
    const [historicalPDF, setHistoricalPDF] = useState([]);
    const [historicalXML, setHistoricalXML] = useState([]);
    const [labelValue, setLabelValue] = useState("La factura no fue localizada en Contpaq");
    const [agentName, setAgentName] = useState(" ");
    const [showVideoPlayer, setShowVideoPlayer] = useState(false);
    const [options, setOptions] = useState();
    const [allPeriodValues, setAllPeriodValues] = useState({
        actualPeriod: {},
        selectedPeriod: {}
    })


    useEffect(async() => {
        setIsLoadingPrefacture(true);
        setCurrentSearch(searchQuery);
        if(searchQuery != "") {
            await Promise.all([getInterns(undefined, undefined, currentLimit, false, searchQuery)])
        } else {
            await Promise.all([getInterns(undefined, undefined, currentLimit, false)])
        }
        setSetData(true);
        setIsLoadingPrefacture(false);
    }, [updateList, isFirstQMPeriod, isSecondQMPeriod, isSecondConsolidationPeriod, isFirstConsolidationPeriod, isConsolidationPrefacture]);

    useEffect(()=> {
        getCompanyByUser();
        handleFactureAgent();
    }, []); 

    const handleFactureAgent = async () => {
        if(context.user.company.factureAgent){
            let response = await Methods.getContpaqAgent(context.user.company.factureAgent);
            if(response != null){
                setAgentName(response.name);
            } else {
                setAgentName("El numero de agente no existe en Contpaq");
            }
        } else {
            setAgentName(" ");
        }
    }

    const getCompanyByUser = async () =>{
        const result =  await Methods.getCompanies([ context.user.company._id]);
        let factureAditionalOptions = await Methods.getPrefactureOptions();
        setOptions(factureAditionalOptions);
        setCompany(result[0]);
    }


    const getAllInterns = async (periodAux) =>{
        let isInsuranceCompany = context.user.company.interns_medical_insurance == "Sí" ? true : false;
        let data = await Methods.getAllApprovedInterns(id_company, periodAux, isInsuranceCompany);
        return data;
    }


    const setInitialPeriod = async () => {
        let payment_period = context.user.company.payment_period;
        let responseDates;
        let dateToVerify = new Date();
        dateToVerify.setHours(0);
        try{
            let response = await axios({
                url: `${CURRENTURL}/periods`,
                method: "POST",
                data: {payment_period: payment_period, dateToVerify: dateToVerify}
            });
            responseDates = response.data;
            responseDates.periodAux = [moment(responseDates.start_date), moment(responseDates.end_date)];
            setAllPeriodValues({
                actualPeriod: responseDates,
                selectedPeriod: responseDates
            })
            setPayDay(responseDates.payment_date);
            setPayDayLimit(responseDates.limit_authorization);
            setactualPayDay(responseDates.payment_date);
            setActualPayDayLimit(responseDates.limit_authorization);
        } catch (error){
            error.response && notify("No hay fechas en el periodo de dispersión actual de la empresa", "error");
            let startDate = new Date();
            startDate.setHours(0,0,0,0);
            let endDate = new Date();
            endDate.setHours(0,0,0,0);
            responseDates.periodAux = [startDate, endDate];
        }    
        setIsMonthly(false);
        setPeriod(responseDates.periodAux);
        setFirstPeriod(responseDates.periodAux);
        setIsFirstPeriod(true);
        return responseDates;
    } 

    const setInitialPayDay = async () =>{
        let payment_period = context.user.company.payment_period;
        let dateToVerify = new Date();
        dateToVerify.setHours(0);
        try{
            let response = await axios({
                url: `${CURRENTURL}/periods`,
                method: "POST",
                data: {payment_period: payment_period, dateToVerify: dateToVerify}
            }); 
            return response.data.payment_date
        } catch (error){
            return undefined;
        }  
    }

    const getPeriods = async (periodAux) => {
        let payment_period = context.user.company.payment_period;
        
        let data = {
            currentPeriod: periodAux,
            payment_period: payment_period
        }

        const [subsequentPeriod, previusPeriod] = await Promise.all([Methods.getSubsequentPeriods(data), Methods.getPreviousPeriods(data)])
        const [previusFirstPeriod, previusSecondPeriod] = previusPeriod;
        const [subsequentFirstPeriod, subsecuentSecondPeriod] = subsequentPeriod;
        setSubPeriods(subsequentPeriod); 
        setPreviousPeriods(previusPeriod);
        setAllPeriodsAux({
            isFirstSubPeriod: subsequentFirstPeriod ? false : true,
            isSecondSubPeriod: subsecuentSecondPeriod ? false : true, 
            isFirstPrevPeriod: previusFirstPeriod ? false : true
        });
    };


    const getInterns = async (startKey, _page, _limit, isQuery = false, query = undefined) => {
        let querysResult;
        let currentPeriodData = !isFirstPeriod ? await setInitialPeriod() : allPeriodValues.selectedPeriod;
        let periodAux = currentPeriodData.periodAux || allPeriodValues.selectedPeriod.periodAux;
        querysResult = await Promise.all([getPeriods(periodAux), Methods.getLastPeriodLimitDate(periodAux, context.user.company.payment_period)])
        let lastPeriodLimitDate = querysResult[1];
        setLastPeriod(lastPeriodLimitDate);

        let totalInterns;
        let response;
        let prefactureDetailId;
        let currentPeriodInterns;
        let prefactureResponse;
        let lastPrefacture;
        let promiseResult;
        let data = await getDataToSend(startKey, isQuery, _limit, query);
        let payDayAux = payDay ? payDay : await setInitialPayDay();

        if(isHistoricalSearch){
            data.historicalInterns = historicalInterns;
            prefactureResponse = await getPrefactureByPayday(payDayAux, id_company);
            prefactureResponse = await getPrefacture(prefactureResponse);
            prefactureDetailId = prefactureResponse ?  prefactureResponse[0].prefacture_detail_id : undefined;
            if(!prefactureResponse){
                setDataToNull();
                return;
            };
            setIsEditable(false);
            prefactureDetailId && await getPrefactureDetailById(prefactureDetailId, true);
            promiseResult = await Promise.all([
                returnInternsByPeriod(prefactureResponse, periodAux, interns, isHistoricalSearch), 
                verifyConsolidationPeriod(lastPeriodLimitDate, currentPeriodData, periodAux, prefactureResponse), canRollBack(currentPeriodData)
            ]);
            totalInterns = promiseResult[0];
        }else{

            promiseResult = await Promise.all([
                getAllInterns(periodAux), 
                getPrefactureByPayday(payDayAux, id_company), 
                getPrefactureByPayday(lastPeriodLimitDate?.payment_date, id_company)
            ]);

            response =  promiseResult[0];
            prefactureResponse = await getPrefacture(promiseResult[1]);
            lastPrefacture = promiseResult[2];
            prefactureDetailId = prefactureResponse ? prefactureResponse[0].prefacture_detail_id : undefined;
            let prefacture = prefactureResponse ? prefactureResponse[0] : undefined;
            if(prefacture == undefined){
                currentPeriodInterns = response;
                setIsApproved(false);
                setIsEditable(true);
                setIsPrefactureDetail(false);
                setSendPrefactureAux({...sendPrefactureAux, prefactureUrl: undefined, prefactureUrlXml: undefined});
            }else{       
                setIsApproved(true);
                setIsEditable(false);
                currentPeriodInterns = prefactureResponse;
                await getPrefactureDetailById(prefactureDetailId, true, false, promiseResult[1]);
                setPrefactureInterns(prefactureResponse);
            }

            if(!response && !prefactureResponse && !lastPrefacture){
                setDataToNull();
                return;
            };

            await Promise.all([
                verifyConsolidationPeriod(lastPeriodLimitDate, currentPeriodData, periodAux, prefactureResponse), 
                canRollBack(currentPeriodData)
            ]);
            let resultPromiseAll = await returnInternsByPeriod(
                currentPeriodInterns, 
                periodAux, 
                prefactureResponse || interns, 
                (prefactureResponse?.length) ? true : false, 
                lastPeriodLimitDate, 
                lastPrefacture
            );
            totalInterns = resultPromiseAll;
            setAllApprovedInterns(resultPromiseAll);
        }
        const SETTINGS = await Methods.getSettingsByIntern(context.user.data._id);
        let sortedField = "curp";
        let sortConfig = "ascending";
        if(SETTINGS?.total > 0){
            sortedField = SETTINGS.settings[0].sortedField;
            sortConfig = SETTINGS.settings[0].sortConfig;
        }
        setPrefactureDetailID(prefactureDetailId);

        setApprovedInterns(sortByColumn(totalInterns, sortedField, sortConfig));
        let totalInterns1 = [];

        
        if(approvedInterns[0]?.college==="Instituto Tecnológico y de Estudios Superiores de Monterrey" && isConsolidationPrefacture ===true && approvedInterns[0]?.payDay ==="30/04/2024"  && approvedInterns[0]?.company_id== 1168 ){
            totalInterns1.push(
                [
                    {
                        "company_id": "1168",
                        "bank": "Bancomer",
                        "isFirstQMPeriod": true,
                        "isMonthlyPayment": false,
                        "isTotalPayment": false,
                        "payDay": "15/04/2024",
                        "lastModifyAccount": "",
                        "projectName": "Sentido Humano",
                        "cost_center_name": "0072204A00",
                        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
                        "faculty": "Chihuahua",
                        "forceApprovePeriod": false,
                        "program": "Experiencias Tec",
                        "firstname": "AARÓN",
                        "_id": "762489d1-018c-11ef-b66d-99b79d80ee9a",
                        "clabe": "2859058788",
                        "isConsolidationPeriod": false,
                        "createdAt": "2024-04-23T10:13:46-06:00",
                        "isSecondQMPeriod": false,
                        "intern_insurance_amount": 0,
                        "nomina": "3551173",
                        "total_amount": 2000,
                        "dispersionStatus": "Pendiente",
                        "intern_id": "82f1a057-489e-11ee-9752-1b187c974e20",
                        "accountColorStatus": "#000000",
                        "period": [
                            "2024-04-01T00:00:00.000Z",
                            "2024-04-15T00:00:00.000Z"
                        ],
                        "leaderTec": "Liliana Ochoa Ortega",
                        "isSecondConsolidationPeriod": false,
                        "directBoss_name": "Javier Joshua Cardoso Legarreta",
                        "isFirstConsolidationPeriod": false,
                        "currentContractData": {
                            "unsuscribeDate": ""
                        },
                        "department_name": "Rector de Operaciones del Tecnológico de Monterrey",
                        "pay_days": 15,
                        "contractEndDate": "26/07/2024",
                        "idInsurancesToPay": [],
                        "compaqStatus": "No requiere",
                        "mother_lastname": "TERRAZAS",
                        "isQMCPrefacture": false,
                        "school_enrollment": "A01562442",
                        "retroactivePayments": [],
                        "scolarshipAmount": "4000.00",
                        "company_insurance_amount": 0,
                        "isConsolidationPrefacture": false,
                        "contractStartDate": "19/02/2024",
                        "father_lastname": "ENRÍQUEZ",
                        "rolledBack": false,
                        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
                        "isQMPrefacture": true,
                        "position": "819879",
                        "statusColor": "#6CE090"
                    }
                ]
               
        );

        totalInterns.push({
            "company_id": "1168",
            "bank": "Bancomer",
            "isFirstQMPeriod": true,
            "isMonthlyPayment": false,
            "isTotalPayment": false,
            "payDay": "15/04/2024",
            "lastModifyAccount": "",
            "projectName": "Soporte a Programa On Campus Intern",
            "cost_center_name": "0702105A0100",
            "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
            "faculty": "Monterrey",
            "forceApprovePeriod": false,
            "program": "Experiencias Tec",
            "firstname": "ANDREA JAZMÍN",
            "_id": "76834ba0-018c-11ef-b66d-99b79d80ee9a",
            "clabe": "1518982798",
            "isConsolidationPeriod": false,
            "createdAt": "2024-04-23T10:13:46-06:00",
            "isSecondQMPeriod": false,
            "intern_insurance_amount": 0,
            "nomina": "3520429",
            "total_amount": 0,
            "dispersionStatus": "Pendiente",
            "intern_id": "237279",
            "accountColorStatus": "#000000",
            "period": [
                "2024-04-01T00:00:00.000Z",
                "2024-04-15T00:00:00.000Z"
            ],
            "leaderTec": "Alicia Lucero Briseño Juárez",
            "isSecondConsolidationPeriod": false,
            "directBoss_name": "Beatriz Anabel Mendoza Godinez",
            "isFirstConsolidationPeriod": false,
            "currentContractData": {
                "unsuscribeDate": "2024-04-12T00:00:00+00:00"
            },
            "department_name": "Rector de Profesional y Posgrado del Tecnológico de Monterrey",
            "pay_days": 0,
            "contractEndDate": "26/07/2024",
            "idInsurancesToPay": [],
            "compaqStatus": "No requiere",
            "mother_lastname": "CRUZ",
            "isQMCPrefacture": false,
            "school_enrollment": "A01174724",
            "retroactivePayments": [],
            "scolarshipAmount": "4000.00",
            "company_insurance_amount": 0,
            "isConsolidationPrefacture": false,
            "contractStartDate": "19/02/2024",
            "father_lastname": "GUZMÁN",
            "rolledBack": false,
            "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
            "isQMPrefacture": true,
            "position": "823180",
            "statusColor": "#6CE090"
        },{
            "company_id": "1168",
            "bank": "Bancomer",
            "isFirstQMPeriod": true,
            "isMonthlyPayment": false,
            "isTotalPayment": false,
            "payDay": "15/04/2024",
            "lastModifyAccount": "",
            "projectName": "Empleabilidad",
            "cost_center_name": "0702105A0423",
            "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
            "faculty": "Estado de México",
            "forceApprovePeriod": false,
            "program": "Experiencias Tec",
            "firstname": "IVÁN ALEXANDER",
            "_id": "78075390-018c-11ef-b66d-99b79d80ee9a",
            "clabe": "1541810753",
            "isConsolidationPeriod": false,
            "createdAt": "2024-04-23T10:13:46-06:00",
            "isSecondQMPeriod": false,
            "intern_insurance_amount": 0,
            "nomina": "3556392",
            "total_amount": 0,
            "dispersionStatus": "Pendiente",
            "intern_id": "7095cc52-d2ab-11ee-a9cf-45364bbc7407",
            "accountColorStatus": "#000000",
            "period": [
                "2024-04-01T00:00:00.000Z",
                "2024-04-15T00:00:00.000Z"
            ],
            "leaderTec": "Edith Eliana Zapata García",
            "isSecondConsolidationPeriod": false,
            "directBoss_name": "Elvia Tress Castelán",
            "isFirstConsolidationPeriod": false,
            "currentContractData": {
                "unsuscribeDate": "2024-04-12T00:00:00-06:00"
            },
            "department_name": "Rector de Profesional y Posgrado del Tecnológico de Monterrey",
            "pay_days": 0,
            "contractEndDate": "26/07/2024",
            "idInsurancesToPay": [],
            "compaqStatus": "No requiere",
            "mother_lastname": "RAMÍREZ",
            "isQMCPrefacture": false,
            "school_enrollment": "A01750817",
            "retroactivePayments": [],
            "scolarshipAmount": "4000.00",
            "company_insurance_amount": 0,
            "isConsolidationPrefacture": false,
            "contractStartDate": "19/02/2024",
            "father_lastname": "RAMOS",
            "rolledBack": false,
            "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
            "isQMPrefacture": true,
            "position": "823199",
            "statusColor": "#6CE090"
        },{
        "company_id": "1168",
        "bank": "Bancomer",
        "isFirstQMPeriod": true,
        "isMonthlyPayment": false,
        "isTotalPayment": false,
        "payDay": "15/04/2024",
        "lastModifyAccount": "",
        "projectName": "Sotryteller",
        "cost_center_name": "9905001A02",
        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
        "faculty": "Monterrey",
        "forceApprovePeriod": false,
        "program": "Storyteller",
        "firstname": "JAVIER EDUARDO",
        "_id": "780c83b0-018c-11ef-b66d-99b79d80ee9a",
        "clabe": "1571322486",
        "isConsolidationPeriod": false,
        "createdAt": "2024-04-23T10:13:46-06:00",
        "isSecondQMPeriod": false,
        "intern_insurance_amount": 0,
        "nomina": " ",
        "total_amount": 0,
        "dispersionStatus": "Pendiente",
        "intern_id": "235587",
        "accountColorStatus": "#000000",
        "period": [
            "2024-04-01T00:00:00.000Z",
            "2024-04-15T00:00:00.000Z"
        ],
        "leaderTec": "Domínguez Ordaz Kathleen Jasleen",
        "isSecondConsolidationPeriod": false,
        "directBoss_name": "José Ángel de León Salas",
        "isFirstConsolidationPeriod": false,
        "currentContractData": {
            "unsuscribeDate": "2024-04-01T00:00:00-06:00"
        },
        "department_name": "Rector de Operaciones del Tecnológico de Monterrey",
        "pay_days": 0,
        "contractEndDate": "30/06/2024",
        "idInsurancesToPay": [],
        "compaqStatus": "No requiere",
        "mother_lastname": "GIORGETTI",
        "isQMCPrefacture": false,
        "school_enrollment": "A01411545",
        "retroactivePayments": [],
        "scolarshipAmount": "4000.00",
        "company_insurance_amount": 0,
        "isConsolidationPrefacture": false,
        "contractStartDate": "14/08/2023",
        "father_lastname": "MARTÍNEZ",
        "rolledBack": false,
        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
        "isQMPrefacture": true,
        "position": "382173",
        "statusColor": "#6CE090"
    },{
        "company_id": "1168",
        "bank": "Bancomer",
        "isFirstQMPeriod": true,
        "isMonthlyPayment": false,
        "isTotalPayment": false,
        "payDay": "15/04/2024",
        "lastModifyAccount": "",
        "projectName": "ESTRATEGIAS DE INTERNACIONALIZACIÓN",
        "cost_center_name": "0700399B00",
        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
        "faculty": "Monterrey",
        "forceApprovePeriod": false,
        "adjustment": 0,
        "program": "Experiencias Tec",
        "firstname": "LUIS ALBERTO",
        "_id": "788a1820-018c-11ef-b66d-99b79d80ee9a",
        "clabe": "1569679487",
        "isConsolidationPeriod": false,
        "createdAt": "2024-04-23T10:13:46-06:00",
        "isSecondQMPeriod": false,
        "intern_insurance_amount": 0,
        "nomina": " ",
        "total_amount": 0,
        "dispersionStatus": "Pendiente",
        "intern_id": "237870",
        "accountColorStatus": "#000000",
        "period": [
            "2024-04-01T00:00:00.000Z",
            "2024-04-15T00:00:00.000Z"
        ],
        "leaderTec": "JENNY VON WESTPHALEN MEDINA IZAGUIRRE",
        "isSecondConsolidationPeriod": false,
        "directBoss_name": "Rosa María Méndez Ruíz",
        "isFirstConsolidationPeriod": false,
        "currentContractData": {
            "unsuscribeDate": "2024-04-01T00:00:00+00:00"
        },
        "department_name": "Rector de Profesional y Posgrado del Tecnológico de Monterrey",
        "pay_days": 0,
        "contractEndDate": "26/07/2024",
        "idInsurancesToPay": [],
        "compaqStatus": "No requiere",
        "mother_lastname": "GARZA",
        "isQMCPrefacture": false,
        "school_enrollment": "A00830844",
        "retroactivePayments": [],
        "scolarshipAmount": "4000.00",
        "company_insurance_amount": 0,
        "isConsolidationPrefacture": false,
        "contractStartDate": "19/02/2024",
        "father_lastname": "VALDEZ",
        "rolledBack": false,
        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
        "isQMPrefacture": true,
        "position": "3519988",
        "statusColor": "#6CE090"
    }, {
        "company_id": "1168",
        "bank": "Bancomer",
        "isFirstQMPeriod": true,
        "isMonthlyPayment": false,
        "isTotalPayment": false,
        "payDay": "15/04/2024",
        "lastModifyAccount": "",
        "projectName": "Diseño de Experiencias Memorables",
        "cost_center_name": "0312204A00",
        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
        "faculty": "Sonora Norte",
        "forceApprovePeriod": false,
        "program": "Experiencias Tec",
        "firstname": "LUIS FERNANDO",
        "_id": "78927c90-018c-11ef-b66d-99b79d80ee9a",
        "clabe": "1513308974",
        "isConsolidationPeriod": false,
        "createdAt": "2024-04-23T10:13:46-06:00",
        "isSecondQMPeriod": false,
        "intern_insurance_amount": 0,
        "nomina": "CREAR",
        "total_amount": 0,
        "dispersionStatus": "Pendiente",
        "intern_id": "82f08ee0-489e-11ee-9752-1b187c974e20",
        "accountColorStatus": "#000000",
        "period": [
            "2024-04-01T00:00:00.000Z",
            "2024-04-15T00:00:00.000Z"
        ],
        "leaderTec": "Monica Apodaca Almada",
        "isSecondConsolidationPeriod": false,
        "directBoss_name": "Paola Lizbeth Miranda García",
        "isFirstConsolidationPeriod": false,
        "currentContractData": {
            "unsuscribeDate": "2024-04-05T00:00:00-06:00"
        },
        "department_name": "Rector de Operaciones del Tecnológico de Monterrey",
        "pay_days": 0,
        "contractEndDate": "26/07/2024",
        "idInsurancesToPay": [],
        "compaqStatus": "No requiere",
        "mother_lastname": "LOPEZ",
        "isQMCPrefacture": false,
        "school_enrollment": "A01252529",
        "retroactivePayments": [],
        "scolarshipAmount": "4000.00",
        "company_insurance_amount": 0,
        "isConsolidationPrefacture": false,
        "contractStartDate": "20/02/2024",
        "father_lastname": "GARCÍA DE LEÓN",
        "rolledBack": false,
        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
        "isQMPrefacture": true,
        "position": "820109",
        "statusColor": "#6CE090"
    },{
        "company_id": "1168",
        "bank": "Bancomer",
        "isFirstQMPeriod": true,
        "isMonthlyPayment": false,
        "isTotalPayment": false,
        "payDay": "15/04/2024",
        "lastModifyAccount": "",
        "projectName": "Global Shared Learning Classroom (Global Classroom)",
        "cost_center_name": "0702009A10",
        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
        "faculty": "Monterrey",
        "forceApprovePeriod": false,
        "program": "Experiencias Tec",
        "firstname": "MANUEL ANDRÉ",
        "_id": "78989710-018c-11ef-b66d-99b79d80ee9a",
        "clabe": "1500049365",
        "isConsolidationPeriod": false,
        "createdAt": "2024-04-23T10:13:46-06:00",
        "isSecondQMPeriod": false,
        "intern_insurance_amount": 0,
        "nomina": "CREAR",
        "total_amount": 0,
        "dispersionStatus": "Pendiente",
        "intern_id": "236070",
        "accountColorStatus": "#000000",
        "period": [
            "2024-04-01T00:00:00.000Z",
            "2024-04-15T00:00:00.000Z"
        ],
        "leaderTec": "Morales Veloquio Gisselle",
        "isSecondConsolidationPeriod": false,
        "directBoss_name": "Francisco Villegas Coronado",
        "isFirstConsolidationPeriod": false,
        "currentContractData": {
            "unsuscribeDate": "2024-04-15T00:00:00-06:00"
        },
        "department_name": "Rector de Operaciones del Tecnológico de Monterrey",
        "pay_days": 0,
        "contractEndDate": "26/07/2024",
        "idInsurancesToPay": [],
        "compaqStatus": "No requiere",
        "mother_lastname": "MEZA",
        "isQMCPrefacture": false,
        "school_enrollment": "A00833706",
        "retroactivePayments": [],
        "scolarshipAmount": "4000.00",
        "company_insurance_amount": 0,
        "isConsolidationPrefacture": false,
        "contractStartDate": "19/02/2024",
        "father_lastname": "GRACIA",
        "rolledBack": false,
        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
        "isQMPrefacture": true,
        "position": "Posicion pendiente",
        "statusColor": "#6CE090"
    },{
        "company_id": "1168",
        "bank": "Bancomer",
        "isFirstQMPeriod": true,
        "isMonthlyPayment": false,
        "isTotalPayment": false,
        "payDay": "15/04/2024",
        "lastModifyAccount": "",
        "projectName": "Spirits",
        "cost_center_name": "0705702A09",
        "college": "Instituto Tecnológico y de Estudios Superiores de Monterrey",
        "faculty": "Puebla",
        "forceApprovePeriod": false,
        "program": "Experiencias Tec",
        "firstname": "PATRICIA",
        "_id": "7968b850-018c-11ef-b66d-99b79d80ee9a",
        "clabe": "1576382368",
        "isConsolidationPeriod": false,
        "createdAt": "2024-04-23T10:13:46-06:00",
        "isSecondQMPeriod": false,
        "intern_insurance_amount": 0,
        "nomina": "Sin registro SSFF",
        "total_amount": 0,
        "dispersionStatus": "Pendiente",
        "intern_id": "bff1c623-d5c9-11ee-a9cf-45364bbc7407",
        "accountColorStatus": "#000000",
        "period": [
            "2024-04-01T00:00:00.000Z",
            "2024-04-15T00:00:00.000Z"
        ],
        "leaderTec": "Ana Gabriela López",
        "isSecondConsolidationPeriod": false,
        "directBoss_name": "Sandra Yadira Sandoval Carreon",
        "isFirstConsolidationPeriod": false,
        "currentContractData": {
            "unsuscribeDate": "2024-04-01T00:00:00-06:00"
        },
        "department_name": "Rector de Operaciones del Tecnológico de Monterrey",
        "pay_days": 0,
        "contractEndDate": "26/07/2024",
        "idInsurancesToPay": [],
        "compaqStatus": "No requiere",
        "mother_lastname": "FLORES",
        "isQMCPrefacture": false,
        "school_enrollment": "A01730600",
        "retroactivePayments": [],
        "scolarshipAmount": "4000.00",
        "company_insurance_amount": 0,
        "isConsolidationPrefacture": false,
        "contractStartDate": "19/02/2024",
        "father_lastname": "DÍAZ",
        "rolledBack": false,
        "prefacture_detail_id": "762489d0-018c-11ef-b66d-99b79d80ee9a",
        "isQMPrefacture": true,
        "position": "820262",
        "statusColor": "#6CE090"
    })

      /*   totalInterns1[0].forEach(item=>{
            totalInterns.forEach(item2=>{
                if(item.intern_id === item2.intern_id){
                    item2.pay_days += item.pay_days;
                    item2.total_amount += item.total_amount; 
                }    
                
            });
        });
        }); */
        const internsMap = {};

        totalInterns.forEach(item => {
            internsMap[item.intern_id] = item;
        });

        totalInterns1[0].forEach(item => {
            const internId = item.intern_id;
            if (internsMap.hasOwnProperty(internId)) {
                const intern = internsMap[internId];
                intern.pay_days += item.pay_days;
                intern.total_amount += item.total_amount;
            }
            else {
                internsMap[internId] = item;
            }
        });






        /* totalInterns1.forEach(item=>{
            if(item.intern_id ==="e94ad4d4-c814-11ed-b409-b514a4b4e493" || item.intern_id ==="bcc965a1-c88e-11ed-b409-b514a4b4e493" ||  item.intern_id ==="e94ad4d5-c814-11ed-b409-b514a4b4e493" || item.intern_id ==="54941350-3ab7-11ed-a729-571fa265b431" || item.intern_id ==="11dd0df0-2ec8-11ed-b28e-572cfc0f588b" || item.intern_id ==="236953" || item.intern_id ==="ffc09cd0-48f3-11ee-91e5-334d71775997" || item.intern_id ==="237890" || item.intern_id ==="237414" ){
                totalInterns.push(item)
            }    
            
        }); */
        }

        setInterns(sortByColumn(totalInterns, sortedField,sortConfig));
        setIsLoading(false);

    };

    const getPrefactureByPayday = async (payDayAux, id_company) => {
        let prefacture =  await Methods.getApprovedPrefactures(payDayAux, [id_company])
        return prefacture || undefined;
    }


    const sortByColumn = (internsToSort, sortedField, sortConfig) => {
        let sortedInterns = internsToSort;
        sortedInterns.sort((a, b) => {
            if (a[sortedField] < b[sortedField]) {
                return sortConfig === 'ascending' ? -1 : 1;
            }
            if (a[sortedField] > b[sortedField]) {
                return sortConfig === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        return sortedInterns;
    }

    const getDataToSend = (startKey, isQuery, _limit, query) =>  {
        let limit = _limit || 10;
        let areApprovedInterns = true;
        let data = {id_company: id_company, limit: limit, approvedInterns: areApprovedInterns};
        if(startKey) data.startKey = startKey;
        if(isQuery) {
            data.isQuery = isQuery;
            data.query = query;
        }
        return data;
    }

    const getPrefacture = async (prefactures) => {

        let prefacturesNotQM = [];
        let prefacturesConsodilations = [];
        let secondPeriodInvalid = [];
        if(prefactures){
            for(let prefacture of prefactures){
                if(!prefacture.isConsolidationPrefacture){
                    prefacturesNotQM.push(prefacture);
                }
                if(isConsolidationPrefacture && prefacture.isConsolidationPrefacture){
                    prefacturesConsodilations.push(prefacture)
                }
                if(prefacture.isConsolidationPrefacture){
                    secondPeriodInvalid.push(prefacture);
                }
            }
        }
        if(isSecondQMPeriod || isSecondConsolidationPeriod){
            await setAllPrefactureQMInterns(prefactures);
        }

        setSecondPeriodNoInterns(secondPeriodInvalid.length ? true : false);
   
        if(prefacturesConsodilations.length && isConsolidationPrefacture){
            return prefacturesConsodilations;
        }
        if(prefacturesNotQM.length) return prefacturesNotQM;
        return undefined;
    }

    const getPrefactureDetailById = async (id, loadValues, QMPeriod, allInternsByQMPrefacture) => {
        let validStatus = ['Pendiente', 'Timbrado', 'Recibido', 'Cancelado']
        try{
            let valuesAux = {}
            if(id) {
                let responsePrefacture = await Methods.getDetailsByPrefacture(id);
                if(!QMPeriod){
                    responsePrefacture.isCanceled && loadValues ? setIsCanceled(responsePrefacture.isCanceled) : setIsCanceled(false);
                    responsePrefacture.historicalPDF && loadValues ? setHistoricalPDF(responsePrefacture.historicalPDF) : setHistoricalPDF([]);
                    responsePrefacture.historicalXML && loadValues ? setHistoricalXML(responsePrefacture.historicalXML) : setHistoricalXML([]);
                    responsePrefacture.documentsNumber && loadValues? setDocumentsNumber(responsePrefacture.documentsNumber) : setDocumentsNumber(1);
                    responsePrefacture.isManuallyUpdated && loadValues ? setIsManuallyUpdated(responsePrefacture.isManuallyUpdated) : setIsManuallyUpdated(false);
                }   
                //CAMBIAR LOS VALORES DE LA PREFACTURA 
                let prefactureTotal = {
                    totalCost : responsePrefacture.totalCost,
                    administrativeExpense : responsePrefacture.administrativeExpense,
                    totalAdministrativeExpense : responsePrefacture.totalAdministrativeExpense,
                    subtotalNoInsurance : responsePrefacture.subtotalNoInsurance,
                    insuranceCompanyData : responsePrefacture.insuranceCompanyData,
                    debitCompany : responsePrefacture.debitCompany,
                    subtotalInsurance : responsePrefacture.subtotalInsurance,
                    creditCompany : responsePrefacture.creditCompany,
                    subtotalCredits : responsePrefacture.subtotalCredits,
                    iva: responsePrefacture.iva,
                    _total : responsePrefacture._total,
                    purchaseOrder: responsePrefacture.purchaseOrder,
                    factureCFDI: context.user.company.factureCFDI,
                    facturePaymentMethod: context.user.company.facturePaymentMethod,
                    facturePaymentType: context.user.company.facturePaymentType,
                    totalDual : responsePrefacture?.totalDual,
                    isDual : responsePrefacture?.isDual,
                    internsNumber : responsePrefacture?.internsNumber,

 

                }
                
                if(approvedInterns[0]?.college==="Instituto Tecnológico y de Estudios Superiores de Monterrey" && isConsolidationPrefacture ===true && approvedInterns[0]?.payDay ==="30/04/2024"  && approvedInterns[0]?.company_id== 1168 ){
                    prefactureTotal = {
                        totalCost : 4840587.99 ,
                        administrativeExpense : responsePrefacture.administrativeExpense,
                        totalAdministrativeExpense : 484058.80,
                        subtotalNoInsurance : 5324646.79,
                        insuranceCompanyData : responsePrefacture.insuranceCompanyData,
                        debitCompany : responsePrefacture.debitCompany,
                        subtotalInsurance : 5324646.79,
                        creditCompany : 23641.60,
                        subtotalCredits : 5301005.19 ,
                        iva: 848160.83,
                        _total : 6149166.01 ,
                        purchaseOrder: responsePrefacture.purchaseOrder,
                        factureCFDI: context.user.company.factureCFDI,
                        facturePaymentMethod: context.user.company.facturePaymentMethod,
                        facturePaymentType: context.user.company.facturePaymentType
                    }
                    
                }


                
                if(loadValues && responsePrefacture.compaqStatus && validStatus.includes(responsePrefacture.compaqStatus)){     
                    valuesAux = {
                        alreadySend: true, 
                        showPurchaseOrderModal: false, 
                        showSendPrefacture: false, 
                        prefactureUrl: responsePrefacture.prefactureUrl, 
                        prefactureUrlXml: responsePrefacture.prefactureUrlXml,
                        canRollBackByStatus: parseInt(responsePrefacture.compaqStatusRecibed || 0) < 0
                    }

                }else if(loadValues && responsePrefacture.compaqStatus && responsePrefacture.compaqStatus == 'No requiere'){
                    valuesAux = {
                        alreadySend: false, 
                        showPurchaseOrderModal: false, 
                        showSendPrefacture: false, 
                        prefactureUrl: undefined, 
                        prefactureUrlXml: undefined,
                        canRollBackByStatus: false
                        
                    }
                }   

                if( (isSecondQMPeriod || isSecondConsolidationPeriod) && QMPeriod ){
                    setPreviusQMPrefactureDetails(prefactureTotal);
                }else{

                    if( (isSecondQMPeriod || isSecondConsolidationPeriod) && !isConsolidationPrefacture && allInternsByQMPrefacture){
                        valuesAux = await verifyConsolidationIsCompaq(valuesAux, allInternsByQMPrefacture);    
                    }

                    setPrefactureData(prefactureTotal);
                    setIsPrefactureDetail(true);
                }

                if(loadValues && !QMPeriod){
                    setSendPrefactureAux({...sendPrefactureAux, ...valuesAux})
                }

            }else{
                setPrefactureData({
                    totalCost: 0,
                    administrativeExpense: 0,
                    totalAdministrativeExpense: 0,
                    subtotalNoInsurance: 0,
                    insuranceCompanyData: 0,
                    debitCompany: 0,
                    subtotalInsurance: 0,
                    creditCompany: 0,
                    subtotalCredits: 0,
                    _total: 0,
                    iva: 0/* ,
                    isDual : false,
                    internsNumber : 0 */
                });
                setIsPrefactureDetail(false);
            }
        } catch (error){
            setIsPrefactureDetail(false);
            error.response && notify("No hay detalle de la prefactura", "error");
        }
    }

    const verifyConsolidationIsCompaq = async (valuesAux, allInternsByQMPrefacture) => {
        let alreadySend = false;
        let canRollBackByStatusAux = false;
        for(let intern of allInternsByQMPrefacture){
            if(intern.isConsolidationPrefacture){
                let responsePrefacture = await Methods.getDetailsByPrefacture(intern.prefacture_detail_id);
                responsePrefacture?.isManuallyUpdated ? setIsManuallyUpdated(responsePrefacture.isManuallyUpdated) : setIsManuallyUpdated(false);
                if(responsePrefacture?.compaqStatus && (responsePrefacture.compaqStatus != 'No requiere')){
                    alreadySend = true;
                    canRollBackByStatusAux = parseInt(responsePrefacture.compaqStatusRecibed || 0) < 0;
                }
                return {...valuesAux, alreadySend: alreadySend, canRollBackByStatus: canRollBackByStatusAux};
            }
        }
    }


    const setDataToNull = async () => {
        setInterns([]);
        setApprovedInterns([]);
        setIsLoading(false);
        setIsPrefactureDetail(false);
    }

    
    const verifyConsolidationPeriod = async (lastPeriodLimitDate, currentPeriodData, periodAux, currentPrefactureAux) => {
        let periodType = getFormatedPayment(context.user.company.payment_period);
        let isForcedPeriod = currentPrefactureAux?.length ? currentPrefactureAux[0].forceApprovePeriod : false;
        if((periodType == "Quincenal-Mensual" || periodType == "Quincenal-Mensual C" || allPeriodValues.selectedPeriod) && !isForcedPeriod){
            await setConsolidationPeriod(lastPeriodLimitDate, currentPeriodData, periodType, periodAux);
        }else{
            setConsolidationValidations({
                ...consolidationValidations, 
                isFirstQMPeriod: false,
                isSecondQMPeriod: false,
                isQMCPrefacture: false,
                isFirstConsolidationPeriod: false,
                isSecondConsolidationPeriod: false,
                forceApprovePeriod: false,
                requiereFirstPeriodApproved: true
            })
        }
    }

    const setConsolidationPeriod = async (lastPeriodAux, currentPeriodData, periodType, periodAux) => {
        let previousPeriodEndDate;
        let currentPeriodEndDate;
        let lastPeriodAuxDate;
        let periodValidations = {
            isFirstQMPeriod: false,
            isSecondQMPeriod: false,
            isQMCPrefacture: false,
            isFirstConsolidationPeriod: false,
            isSecondConsolidationPeriod: false,
            requiereFirstPeriodApproved: true
        }   
        let isFirstConsolidationPeriod = currentPeriodData.isFirstConsolidationPeriod || allPeriodValues.selectedPeriod?.isFirstConsolidationPeriod
        let isSecondConsolidationPeriod = currentPeriodData.isSecondConsolidationPeriod || allPeriodValues.selectedPeriod?.isSecondConsolidationPeriod
        let forceApprovePeriod = currentPeriodData.forceApprovePeriod || allPeriodValues.selectedPeriod?.forceApprovePeriod;
        if(lastPeriodAux || allPeriodValues.selectedPeriod){
            if(lastPeriodAux === undefined){
                lastPeriodAux = {
                    limit_authorization: "17/01/2024",
                    payment_date: "19/01/2024",
                    end_date: "18/01/2024",
                    start_date: "05/01/2024",
                    payment_period: "Catorcenal",
                    _id: "78-cat"
                }
            }
            previousPeriodEndDate = new Date(getFormatedDate(lastPeriodAux?.end_date));
            currentPeriodEndDate = new Date((periodAux[0]));   
            lastPeriodAuxDate = [
                new Date(getFormatedDate(lastPeriodAux.start_date) + " 00:00:00"), 
                new Date(getFormatedDate(lastPeriodAux.end_date) + " 00:00:00")
            ];
            if(periodType == 'Quincenal-Mensual'){
                periodValidations.isSecondQMPeriod = previousPeriodEndDate.getMonth() == currentPeriodEndDate.getMonth();
                periodValidations.isFirstQMPeriod = previousPeriodEndDate.getMonth() != currentPeriodEndDate.getMonth();
            }else if(isFirstConsolidationPeriod || isSecondConsolidationPeriod){
                if( forceApprovePeriod && periodType == 'Quincenal-Mensual C'){
                    periodValidations = {
                        ...periodValidations,
                        isFirstConsolidationPeriod : isFirstConsolidationPeriod,
                        isSecondConsolidationPeriod : isSecondConsolidationPeriod,
                        forceApprovePeriod : true,
                        isQMCPrefacture : true,
                        isSecondQMPeriod : true,
                        requiereFirstPeriodApproved: false
                    }
                }else if(!forceApprovePeriod){
                    periodValidations.isFirstConsolidationPeriod = isFirstConsolidationPeriod;
                    periodValidations.isSecondConsolidationPeriod = isSecondConsolidationPeriod;
                }
            }else if(periodType == 'Quincenal-Mensual C'){
                periodValidations.isQMCPrefacture = true;
                periodValidations.isSecondQMPeriod = previousPeriodEndDate.getMonth() != currentPeriodEndDate.getMonth();
                periodValidations.isFirstQMPeriod = previousPeriodEndDate.getMonth() == currentPeriodEndDate.getMonth();
            }
        } 
        if ( periodValidations.isSecondQMPeriod || periodValidations.isSecondConsolidationPeriod ) await setPrefactureQMPeriod(lastPeriodAuxDate, id_company);
        setConsolidationValidations(periodValidations);
        
    }


    const setPrefactureQMPeriod = async (lastPeriodAuxDate, id_company) => {
        let lastPrefacture = await Methods.getPrefactureByPeriod(lastPeriodAuxDate, id_company);
        if(lastPrefacture) { 
            await Promise.all([
                getPrefactureDetailById(lastPrefacture[0].prefacture_detail_id, false, true), 
                setPreviusQMPrefacture(lastPrefacture)
            ]);
        }
    }

    const canRollBack = async (periodAux) => {
        try{
            let nextPeriodEndDate = new Date(getFormatedDate(subPeriods[0].end_date));
            let currentPeriodEndDate = new Date(periodAux.periodAux[0]);
            let canRollBackFirstPeriodAux = true;  
            if(subPeriods){
                if(nextPeriodEndDate.getMonth() == currentPeriodEndDate.getMonth()){
                    let _startDate = new Date(getFormatedDate(subPeriods[0].start_date) + " 00:00:00");
                    let _endDate = new Date(getFormatedDate(subPeriods[0].end_date) + " 00:00:00");
                    let lastPeriodAuxDate = [_startDate, _endDate]; 
                    let lastPrefacture = await Methods.getPrefactureByPeriod(lastPeriodAuxDate, id_company);
                    canRollBackFirstPeriodAux  = lastPrefacture ? false : true;
                }
                setCanRollBackFirstQMPeriod(canRollBackFirstPeriodAux)
            }
        }catch(error){
            notify(error, "error");
        }
    }


    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/"+ year;
        } else {        
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }

    const getHistoricalFormatedDate = (dateString) =>  {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            return day + "/" + month + "/"+ year;
        } 
        return dateString
    }

    const addZeroToDate =  (value) => {
        return (value && value.length == 1) ?  "0" + value : value;
    };
    
    const returnInternsByPeriod = async (data, periodAux, prefactureResponse, isHistoricalSearch, lastPeriodLimitDate, lastPrefacture) => {
        const [periodStartDate, periodEndDate] = periodAux;
        
        let interns = [];
        let promises = [];
        let variablesAux = {
            monthlyPayments: [],
            totalPayments: [],
            lastPeriodLimitDateAux: "",
            _startDate: "",
            _endDate: "",
            lastPeriodAuxDate: "",
            lastPrefacture: "",
            retroactivePayments: []
        }

        if(lastPeriodLimitDate && lastPrefacture){
            variablesAux._startDate = new Date(getFormatedDate(lastPeriodLimitDate.start_date) + " 00:00:00");
            variablesAux._endDate = new Date(getFormatedDate(lastPeriodLimitDate.end_date) + " 00:00:00");
            variablesAux.lastPeriodAuxDate = [variablesAux._startDate, variablesAux._endDate]; 
            variablesAux.lastPrefacture = lastPrefacture
            variablesAux.lastPeriodLimitDateAux = moment(getFormatedDate(lastPeriodLimitDate.limit_authorization));
            variablesAux.lastPeriodLimitDateAux.startOf('day');
        }
        if(data?.length){
            if(isHistoricalSearch){
                for(let intern of data){
                    const contractEndDateFormated = getFormatedDate(intern.contractEndDate);
                    const contractEndDate = new Date(contractEndDateFormated);
                    intern.statusColor = getInternStatusColor(contractEndDate, periodEndDate);
                    interns.push(intern);
                }
            }else{
                for(let intern of data){
                    promises.push(verifyIntern(intern, interns, variablesAux, periodAux, periodEndDate, periodStartDate));
                }
                await Promise.all(promises);
            }
        }
        setPrefacturePaymentsAux({ 
            insuranceTotal: variablesAux.totalPayments,
            retroactivePayments: variablesAux.retroactivePayments
        })
        return interns;
    };


    const verifyIntern = async (intern, interns, variablesAux, periodAux, periodEndDate, periodStartDate) => {
        let errors = 0;
        let _intern = intern;
        let contractIntern = intern.currentContractData;
        contractIntern.contractEndDate = getHistoricalFormatedDate(contractIntern.internEndDate);
        contractIntern.contractStartDate = getHistoricalFormatedDate(contractIntern.internStartDate);
        let currentContractDates = {
            contractEndDate: new Date(getFormatedDate(contractIntern.contractEndDate)),
            contractStartDate: new Date(getFormatedDate(contractIntern.contractStartDate))
        };
        let periodEndDateAux = new Date(periodEndDate);
        let newTotalCost = {};
        const requiereDifference = periodEndDate.diff(periodStartDate, 'days') >= getDaysToPay() ? false : true
        _intern.isRenewIntern = false;
        _intern.daysToPayRenew = 0;
        _intern.paymentRenew = 0;
        _intern.lastContractsDays = 0;
        _intern.lastContractsPayments = 0;
        _intern.renewedContractsDays = 0;
        _intern.renewedContractsPayments = 0;
        _intern.company_insurance_amount = 0;
        _intern.intern_insurance_amount = 0;

        if(getInternTotalErrors(_intern, periodStartDate, periodEndDateAux, currentContractDates)) return;

        const {contractEndDate, contractStartDate} = currentContractDates;
    
        if(moment(contractStartDate).diff(moment(periodStartDate), 'days') > 0 && !_intern.isRenewIntern){
            let lastContracts = await Methods.getLastContractPaymentDays(_intern, intern.id_company, periodAux);
            if(lastContracts.length){
                for(let debits of lastContracts){
                    _intern.lastContractsDays += debits.daysToPay || 0;
                    _intern.lastContractsPayments += debits.paymentAdjustment || 0;
                }
            }
        }

        if(_intern.currentContractData.contractApprovalDate && !_intern.isRenewIntern){
            verifyInternOwePayment(_intern, periodEndDateAux, periodStartDate, contractStartDate, variablesAux, periodAux);
        }

        if(intern.renewedContracts && intern.renewedContracts.length){
            for(let contract of intern.renewedContracts){
                if(_intern.contractPendingAuxId ? contract._id != _intern.contractPendingAuxId : true){
                    let endDateToEvaluate = moment(contract.unsuscribeDate ? contract.unsuscribeDate : getFormatedDate(contract.internEndDate));
                    let currentPeriodEndDate = moment(periodEndDate)
                    if(currentPeriodEndDate.isBefore(endDateToEvaluate)){
                        endDateToEvaluate = currentPeriodEndDate;
                    }
                    _intern.renewedContractsDays += endDateToEvaluate.diff(moment(getFormatedDate(contract.internStartDate)), 'days') + (requiereDifference ? 1 : 0);
                    _intern.renewedContractsPayments += parseFloat(parseFloat((contract ? contract.internAmount : 0) / 30) * _intern.renewedContractsDays);
                }
            }
        }

        _intern.statusColor = getInternStatusColor(contractEndDate, periodEndDate, contractIntern.unsuscribeDate);
        newTotalCost = getInsurancePayment(_intern, variablesAux.totalPayments);
        _intern.company_insurance_amount = parseFloat(newTotalCost.companyInsurance);
        _intern.intern_insurance_amount = parseFloat(newTotalCost.internInsurance);
        if(errors == 0) {
            interns.push(_intern);
        }
    }


    const getInternTotalErrors = (_intern, periodStartDate, periodEndDateAux, currentContractDates) => {
        let contractAux;

        if(currentContractDates.contractStartDate > currentContractDates.periodEndDateAux) return true;

        if(periodStartDate > currentContractDates.contractEndDate) {
            if(_intern.renewedContracts && _intern.renewedContracts.length){
                const contractsSorted = _intern.renewedContracts.sort(function(a,b){
                    let momentA = a.internStartDate.includes("/") ? moment(a.internStartDate,"DD/MM/YYYY").startOf("day"): moment(a.internStartDate.slice(0,10)).startOf("day");
                    let momentB = b.internStartDate.includes("/") ? moment(b.internStartDate,"DD/MM/YYYY").startOf("day"): moment(b.internStartDate.slice(0,10)).startOf("day");
                    return momentA.isAfter(momentB) ? -1 : 1;
                });
                
                for(let contract of contractsSorted){
                    let startDateContractAux =  contract.internStartDate.includes("/") ? moment(contract.internStartDate,"DD/MM/YYYY").startOf("day"): moment(contract.internStartDate.slice(0,10)).startOf("day");
                    if( 
                        contract.contractStatus == 'Pendiente' && 
                        moment(periodEndDateAux).isSameOrAfter(startDateContractAux)
                        && ( contract.unsuscribeDate ? !moment(contract?.unsuscribeDate).isBefore(periodEndDateAux) : true )
                    ){
                        contractAux = contract;
                        _intern.contractEndDate = contract.internEndDate;
                        _intern.contractStartDate = contract.internStartDate;
                        currentContractDates.contractEndDate = new Date(getFormatedDate(contract.internEndDate));
                        currentContractDates.contractStartDate = new Date(getFormatedDate(contract.internStartDate));
                        _intern.scolarshipAmount = contract.internAmount;
                        _intern.contractPendingAuxId = contract._id;
                        _intern.currentContractData = contract;
                    }
                }
            }
            if(!contractAux) return true;
        }


        if(currentContractDates.contractStartDate > periodEndDateAux) return true

        
        return false;
    }


    const getInternStatusColor = (contractEndDate, periodEndDate, unsuscribeDate) => {
        let daysBeforeContractEnds = getDaysBeforeContractEnds(contractEndDate, periodEndDate, unsuscribeDate);
        if(daysBeforeContractEnds >= 2) return "#6CE090";
        if(daysBeforeContractEnds == 1) return "#FCBA02";
        if(daysBeforeContractEnds == 0) return "#ED5A6C";
    }

    const verifyInternOwePayment = (_intern, periodEndDateAux, periodStartDate, contractStartDate, variablesAux, periodAux) =>  { 
        let startPeriodFormated = moment(periodAux[0] || period[0]).format('DD/MM/YYYY');
        let internApprovalDateAux = new Date(_intern.currentContractData.contractApprovalDate);
        let lastPrefactureApprovalDate = variablesAux?.lastPrefacture[0]?.created_At?.startDate || variablesAux?.lastPrefacture[0]?.createdAt;

        if(_intern.currentContractData.isRetroactivePayment && (!_intern.currentContractData.retroactivePaymentDate || _intern.currentContractData.retroactivePaymentDate == startPeriodFormated)){
            _intern.daysToPayRenew += moment(periodStartDate).diff(moment(contractStartDate), 'days') > 0 ? moment(periodStartDate).diff(moment(contractStartDate), 'days') : 0;
            _intern.paymentRenew += parseFloat(parseFloat(_intern.scolarshipAmount / 30) * _intern.daysToPayRenew);
            variablesAux.retroactivePayments.push({contractId: _intern.currentContractData._id, payDay: startPeriodFormated});
            _intern.retroactivePayments = [];
            _intern.retroactivePayments.push({contractId: _intern.currentContractData._id, payDay: startPeriodFormated});
        }else if(internApprovalDateAux >= periodStartDate && periodEndDateAux >= internApprovalDateAux && contractStartDate < periodStartDate){
            _intern.daysToPayRenew += moment(periodStartDate).diff(moment(contractStartDate), 'days');
            _intern.paymentRenew += parseFloat(parseFloat(_intern.scolarshipAmount / 30) * _intern.daysToPayRenew);
        } else if(variablesAux.lastPrefacture && ((moment(_intern.currentContractData.contractApprovalDate).diff(lastPrefactureApprovalDate, 'days')) >= 1)){
            pendingPaymentDays(_intern, periodStartDate, contractStartDate, 1);
        } else if (lastPrefactureApprovalDate){
            let lastPrefactureAutDate = new Date(lastPrefactureApprovalDate);
            if(internApprovalDateAux > lastPrefactureAutDate){
                pendingPaymentDays(_intern, periodStartDate, contractStartDate, 0);       
            }
        }
    }

    const pendingPaymentDays = (intern, periodStartDate, contractStartDate, maxLimitDayDifference) => {
        let daysByMonth = 0;
        let monthDifference = moment(periodStartDate).diff( moment(contractStartDate), "months");

        if (monthDifference > 0) {
            for (let m = monthDifference; m > 0; m--) {
                let monthDayAux = moment(periodStartDate)
                    .subtract(m, "month")
                    .endOf("month")
                    .startOf("day");
                if (
                    moment(contractStartDate).isSameOrBefore(monthDayAux) &&
                    monthDayAux.daysInMonth() != 30
                ) {
                    daysByMonth += monthDayAux.daysInMonth() > 30 ? -1 : 0;
                }
            }
        }

        if(moment(periodStartDate).diff(moment(contractStartDate), "days") >= maxLimitDayDifference){
            intern.daysToPayRenew += moment(periodStartDate).diff( moment(contractStartDate), "days") || 0;
            intern.daysToPayRenew += daysByMonth;
            intern.paymentRenew += parseFloat(
                parseFloat(intern.scolarshipAmount / 30) * intern.daysToPayRenew
            );
        }
    };


    const getInsurancePayment =  (intern, totalPayments) => {
        let cost = 0;
        let result = {
            internInsurance: 0,
            companyInsurance: 0
        }
        
        if(intern.currentInsuranceData){
            if(!intern.currentInsuranceData.isPaid){
                intern.currentInsuranceData.policyCost = intern.currentInsuranceData.policyCostAux ? intern.currentInsuranceData.policyCostAux.replace('$', '') : (intern.currentInsuranceData.policyCost ? intern.currentInsuranceData.policyCost.replace('$', '') : 0);
                cost = parseFloat(intern?.currentInsuranceData?.policyCost || 0);
                if(cost){
                    result.internInsurance = intern.currentInsuranceData.insurancePayment == "Practicante" ? cost : 0;
                    result.companyInsurance = intern.currentInsuranceData.insurancePayment == "Empresa" ?  cost : 0;
                    totalPayments.push({insuranceId: intern.currentInsuranceData._id, idIntern: intern._id});
                }
            }
        }

        if(intern.insuranceDebts && intern.insuranceDebts.length){
            for(let insurance of intern.insuranceDebts){
                insurance.policyCost = insurance.policyCost.toString() ? insurance.policyCost.toString().replace('$', '') : 0;
                cost = parseFloat(insurance.policyCost || 0)
                if(cost){
                    result.internInsurance = result.internInsurance + (insurance.insurancePayment == "Practicante" ? cost : 0);
                    result.companyInsurance = result.companyInsurance + (insurance.insurancePayment == "Empresa" ? cost : 0);
                    totalPayments.push({insuranceId: insurance._id, idIntern: intern._id});
                }
            }
        }

        return result;

    };


    const getFormatedPayment = (payment_period)=>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }


    const getDaysToPay = () => {
        const type = getFormatedPayment(context.user.company.payment_period);
        const params = {
            "Catorcenal": 14,
            "Quincenal": 15,
            "Quincenal 2": 15,
            "Mensual": 30,
            "Mensual 15": 30,
            "Mensual 30": 30,
            "Quincenal-Mensual": 15,
            "Quincenal-Mensual C": 15,
            "Mensual 15 BAT": 30
        };
        
        return params[type];
    };

    const getDaysBeforeContractEnds = (contractEndDate, periodEndDate, unsuscribeDate) => {
        let a = moment(periodEndDate,'M/D/YYYY');
        let b = unsuscribeDate ? moment(unsuscribeDate).startOf('day') : moment(contractEndDate,'M/D/YYYY');
        let diffDays = b.diff(a, 'days');
        let periodDays = getDaysToPay();
        if(diffDays > periodDays) return 2;
        if(diffDays > 0) return 1;
        return 0;
    };

    const getPeriodString = () => {
        const [startDate, endDate] = period;
        const a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
        const b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
        const c = moment(endDate, 'D/M/YYYY');
        var fecha = moment(endDate, "D/MM/YYYY");
        fecha.date(1);
        fecha.add(1, 'months');
        fecha.subtract(1, 'days');
        var ultimoDia = fecha.format('D/MM/YYYY');
        let currentYear = c.year();

        if(getPeriodStringUnformat() === `Periodo del 17/11/${currentYear} al ${ultimoDia}` && getFormatedPayment(context.user.company.payment_period) === "Quincenal-Mensual C"){
            return <h5>Periodo del {a} al {ultimoDia}</h5>;
        }

        return <h5>Periodo del {a} al {b}</h5>;
    };


    const getPeriodStringUnformat = () => {
       

        const [startDate, endDate] = period;
        const a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
        const b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
       
        return `Periodo del ${a} al ${b}`;
    };

    const getConsolidationPeriodString = () => {
        
        const [startDate, endDate] = period;
        let a = moment(getFormatedDate(lastPeriod.start_date)).format('D/MM/YYYY');
        let b =  moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
        const c = moment(endDate, 'D/M/YYYY');

        var fecha = moment(endDate, "D/MM/YYYY");
        fecha.date(1);
        fecha.add(1, 'months');
        fecha.subtract(1, 'days');
        var ultimoDia = fecha.format('D/MM/YYYY');
        let currentYear = c.year();

        if(getPeriodStringUnformat() === `Periodo del 17/11/${currentYear} al ${ultimoDia}` && getFormatedPayment(context.user.company.payment_period) === "Quincenal-Mensual C"){
            a = moment(startDate, 'D/M/YYYY').format('D/MM/YYYY');
            b = moment(endDate, 'D/M/YYYY').format('D/MM/YYYY');
            return <h5>Periodo del {a} al {ultimoDia}</h5>;
        }

        return <h5>Factura mensual del {a} al {b}</h5>;
    };

    const showRollBack = () => {
        if (isConsolidationPrefacture) return false;
        return (
            (isApproved || secondPeriodNoInterns) &&
            isCaintraUser &&
            (context.can("rollback", "Prefacture") ||
                context.userRole == "Administrador Caintra") &&
            !isHistoricalSearch
        );
    };
    
    const downloadXML = async(urlAux) => {
        let responseXML = await axios({
            url: urlAux,
            method: "GET",
        });

        if (responseXML) {
            const data = new Blob([responseXML.data], { type: "text/plain" });
            const url = URL.createObjectURL(data);
            let elementAux = document.createElement("a");
            elementAux.download = "factura.xml";
            elementAux.href = url;
            document.body.appendChild(elementAux);
            elementAux.click();
            document.body.removeChild(elementAux);
        } else {
            notify("Ocurrió algo inesperado", "error");
        }
    }

    const verifyIfCanStamp = async() => {
        let extraLimitDates = {
            'Catorcenal': 2
        }
        
        let currentPaymentPeriod = context.user.company.payment_period;

        let stampedStatus = await Methods.getStampedStatus();
        if(!stampedStatus?.canStampInvoice){
            notify('El sistema de timbrado por el momento está en mantenimiento. Te sugerimos, lo intentes en 24 horas o consulta con tu asesor. Lamentamos el inconveniente.', 'error');
            return;
        }

        let currentDate = moment.tz(moment().format(), "America/Mexico_City");
        const fridayMondayValidation = () => {
            let hourToValidate = (currentDate.weekday() === 1 ) ? 7 : (currentDate.weekday() === 5 ? 17 : 0);
            let now = moment.tz(moment().format(), "America/Mexico_City");
            if(!hourToValidate) return;
            var dateToCheck = now.hour(hourToValidate).minute(0).second(0);
            if(currentDate.weekday() === 1) return dateToCheck.isAfter(moment.tz(moment().format(), "America/Mexico_City"));
            return moment.tz(moment().format(), "America/Mexico_City").isAfter(dateToCheck);
        }

        if(!isCaintraUser){
            if((currentDate.weekday() === 6 || currentDate.weekday() === 0) || fridayMondayValidation()){
                notify('Los horarios para poder enviar el timbrado de facturas son de lunes 7 a.m. a viernes 5 p.m. Si tienes dudas te sugerimos consultar con tu asesor.', 'error');
                return;
            }
        
            let [startDate, endDate] = period;
    
            if(isConsolidationPrefacture){
                startDate = getFormatedDate(lastPeriod.start_date);
            }
    
            if(currentDate.isBefore(moment(startDate).subtract(1, 'day'))){
                notify(`El proceso de timbrado no se puede realizar antes de la fecha de inicio del periodo.`, 'error');
                return;
            }

            if(currentDate.isAfter(moment(payDayLimit, 'DD/MM/YYYY').add((extraLimitDates[currentPaymentPeriod] || 3) + 1 , 'days'))){
                notify(`El proceso de timbrado no se puede realizar ${currentDate.diff(moment(payDayLimit, 'DD/MM/YYYY'), 'days')} días después de la fecha de corte.
                Te sugerimos contactar con tu asesor para continuar el proceso.`, 'error');
                return;
            }
        }
        if(context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fecha2) >= Date.parse(fecha1) /* && context.user.company.payment_period !=="Quincenal-Mensual C" */ ){
            setVerifyPreviewMD(true); 
            setShowPreviewMD(!showPreviewMD)
        }
        else{
            setVerifyPreview(true); 
            setShowPreview(!showPreview)
        }
       
    }

    const isNotSecondPeriod = () => !isSecondQMPeriod && !isSecondConsolidationPeriod;

    const isNotFirstPeriod = () => !isFirstQMPeriod && !isFirstConsolidationPeriod;

    const isSecondPeriod = () => {

        //MY CHANGES
         /* if(getPeriodStringUnformat() === "Periodo del 1/02/2024 al 15/02/2024" &&  getFormatedPayment(context.user.company.payment_period) === "Quincenal-Mensual C"){
            isFirstQMPeriod = false;
            isSecondQMPeriod = true;
            isFirstConsolidationPeriod= true;
            isSecondConsolidationPeriod= false;
        } 
        console.log("11111111",isSecondQMPeriod);
        console.log("222222222",isSecondConsolidationPeriod);
 */
        
        return  isSecondQMPeriod || isSecondConsolidationPeriod
    }
    const validateConsolidationPeriod = () => {
        if(context.user.company.payment_period === "Quincenal-Mensual C" && 
        !isSecondQMPeriod && !isConsolidationPrefacture){
            return false;
        }
       
        return ((isPrefactureDetail && isConsolidationPrefacture) || (consolidationValidations.forceApprovePeriod && isPrefactureDetail)
        || (!isConsolidationPrefacture && isNotFirstPeriod() && isNotSecondPeriod()))
    }
    
    return(
        <div className="prefecture">
            {isLoading &&
                <p>Cargando...</p>
            }
            {currentTab == "prefactureList" &&
                <>
                    <div className='title-video-container'>
                        <div className="title-container">
                            <h4>Pre-factura</h4>
                            {!isConsolidationPrefacture ? getPeriodString() : getConsolidationPeriodString()}
                            <h3 className="company">{currentCompany}</h3>
                        </div>

                        <div className="video-container" onClick={ () => { setShowVideoPlayer(!showVideoPlayer)}}>  
                            <img src={videoPlay} size={25} />
                            <h3 className='video-text'>Tutorial</h3>
                        </div>
                    </div>

                    <div className="header-container-prefacture">
                        <div className='search-container-prefacture'>
                            { !isConsolidationPrefacture &&
                                <Button className="authorize-button" disabled={isApproved || secondPeriodNoInterns} onClick={() => setShowApprovePrefecture(!showApprovePrefecture)}>
                                    Autorizar
                                </Button>
                            }
                            { (showRollBack()) &&  <div className="search-and-authorize">
                                <Button className="authorize-button" 

                                disabled={                                        ((!isApproved && !secondPeriodNoInterns)
                                        || ((!canRollBackByStatus && !isManuallyUpdated) && alreadySend))
                                    }
                                    onClick={() => setShowRollBackPrefacture(!showRollBackPrefacture)}>
                                    <AiOutlineRollback/>
                                    Roll-back
                                </Button>
                            </div>
                            }
                                   
                           
                            { ( isSecondPeriod() && (isPrefactureDetail || secondPeriodNoInterns) && !consolidationValidations.forceApprovePeriod) && 
                                <div className="search-and-authorize" >
                                    <Button className={"authorize-button-disabled" } onClick={() => setIsConsolidationPrefacture(!isConsolidationPrefacture)}>
                                    {!isConsolidationPrefacture ? "Factura mensual" : "Segundo período"}
                                    </Button>
                                </div>
                            }

                            <div className="search">
                                <div className="search-icon" title="Buscar" onClick={() => {
                                        setSearchQuery(document.getElementById("search-text").value);
                                        setUpdateList(!updateList);
                                    }}><RiSearch2Line/>
                                </div>
                                <Form.Group controlId="search-bar" className="search-container">
                                    <Form.Control
                                        type="text"
                                        placeholder="Búsqueda de Pre-factura"
                                        autoComplete={"off"}
                                        id="search-text"
                                        onKeyDown={ (e) => {
                                            if (e.keyCode == 13) {
                                                setSearchQuery(e.target.value);
                                                setUpdateList(!updateList);
                                            }
                                        }}
                                        onInputCapture={
                                            (e) => {
                                                setSearchQuery(e.target.value);
                                                setUpdateList(!updateList)
                                            }
                                        }
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className='compaq-buttons'>
                            <div className="float-right search-and-authorize margin" >

                           
                                {  validateConsolidationPeriod() && !prefactureUrl && !prefactureUrlXml &&
                                    <Button className="authorize-button-text" disabled={!isPrefactureDetail} onClick={() => {setShowStatusContpaq(!showStatusContpaq)}}>
                                        Status
                                    </Button>
                                }
                                {prefactureUrl ? 
                                    <Button className="authorize-button-text" href={prefactureUrl} target="_blank">
                                        Factura PDF
                                    </Button> :
                                    validateConsolidationPeriod() ? 
                                    <Button className="authorize-button-text" disabled={!isApproved || alreadySend} onClick={async () => { await verifyIfCanStamp();}}>
                                        Timbrar
                                    </Button> : <></>
                                }
                                {prefactureUrlXml &&
                                    <Button className="authorize-button-text" onClick={() => {downloadXML(prefactureUrlXml)}}>
                                        Factura XML
                                    </Button> 
                                }
                            </div>
                            <div className="float-right search-and-authorize margin" >
                                {isCanceled && validateConsolidationPeriod() && 
                                    <Button className="authorize-button-text" onClick={() => {setUploadManually(!uploadManually)}}>
                                        Cargar
                                    </Button>
                                }

                                {   validateConsolidationPeriod() && !prefactureUrl && !prefactureUrlXml && (context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fecha2) <= Date.parse(fecha1) && context.user.company.payment_period ==="Quincenal-Mensual C")  && 
                                    <Button className="authorize-button-text" onClick={() => {setShowPreview(!showPreview)}}>
                                        Preview
                                    </Button>
                                }
                                {   validateConsolidationPeriod() && !prefactureUrl && !prefactureUrlXml  && !context.user.company.business_name.toUpperCase().includes("DUAL") && 
                                    <Button className="authorize-button-text" onClick={() => {setShowPreview(!showPreview)}}>
                                        Preview
                                    </Button>
                                }
                                {   validateConsolidationPeriod() && !prefactureUrl && !prefactureUrlXml && context.user.company.business_name.toUpperCase().includes("DUAL")  && Date.parse(fecha2) >= Date.parse(fecha1) /* && context.user.company.payment_period !=="Quincenal-Mensual C" */ &&
                                    <Button className="authorize-button-text" onClick={() => {setShowPreviewMD(!showPreviewMD)}}>
                                        Preview MD
                                    </Button>
                                }
        
                            </div>
                            <div className="float-right search-and-authorize margin" >
                                { ((isCaintraUser && isPrefactureDetail && prefactureUrl && prefactureUrlXml ) ||
                                 (isCaintraUser && context.can('cancelInvoice', 'Prefacture') && alreadySend && validateConsolidationPeriod() && isApproved) ) &&
                                    <Button className="authorize-button-text" onClick={() => {setShowCancel(!showCancel)}}>
                                        Cancelar
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>

                    <ListApprovedInterns 
                        totalDual = {totalDual}
                        countInterns = {allApprovedInterns.length}
                        setTotalDual = {setTotalDual}
                        allApprovedInterns={allApprovedInterns}
                        setApprovedInterns={setApprovedInterns}
                        approvedInterns={approvedInterns}
                        firstPeriod={firstPeriod} 
                        period={period} 
                        setPeriod={setPeriod} 
                        setUpdateList={setUpdateList} 
                        updateList={updateList}
                        isApproved={isApproved}
                        setCurrentTab={setCurrentTab} 
                        setCurrentInternId={setCurrentInternId}
                        searchQuery={searchQuery} 
                        isMonthly={isMonthly}
                        setPrefactureData={setPrefactureData}
                        prefactureData={prefactureData}
                        isHistoricalSearch={isHistoricalSearch}
                        setIsHistoricalSearch={setIsHistoricalSearch}
                        setSpecificMonth={setSpecificMonth}
                        setSpecificYear={setSpecificYear}
                        specificMonth={specificMonth}
                        specificYear={specificYear}
                        historicalInterns={historicalInterns}
                        setHistoricalInterns={setHistoricalInterns}
                        subPeriods={subPeriods}
                        previousPeriods={previousPeriods}
                        isFirstSubPeriod={isFirstSubPeriod}
                        isSecondSubPeriod={isSecondSubPeriod}
                        isFirstPrevPeriod={isFirstPrevPeriod}
                        isPrefactureDetail={isPrefactureDetail}
                        setData={setData}
                        setSetData={setSetData}
                        isEditable={isEditable}
                        setPayDay={setPayDay}
                        setPayDayLimit={setPayDayLimit}
                        actualPayDay={actualPayDay}
                        actualPayDayLimit={actualPayDayLimit}
                        setIsConsolidationPrefacture = {setIsConsolidationPrefacture}
                        company={company}
                        lastPeriod={lastPeriod}
                        isConsolidationPrefacture={isConsolidationPrefacture}
                        setSendPrefactureAux={setSendPrefactureAux}
                        sendPrefactureAux={sendPrefactureAux}
                        showRollBack={showRollBack}
                        setAllPeriodValues={setAllPeriodValues}
                        allPeriodValues={allPeriodValues}
                    />

                    <UpdateClabe
                        showUpdateUser={showUpdateUser}
                        setShowUpdateUser={setShowUpdateUser}
                        internToUpdate={internToUpdate}
                        context={context}
                        setUpdateInternList={setUpdateInternList}
                        updateInternList={updateInternList}
                    />

                    <StatusContpaq
                        showStatusContpaq={showStatusContpaq}
                        setShowStatusContpaq={setShowStatusContpaq}
                        prefactureDetailID={prefactureDetailID}
                        setLabelValue={setLabelValue}
                        labelValue={labelValue}
                        alreadySend={alreadySend}
                        isCanceled={isCanceled}
                        isManuallyUpdated={isManuallyUpdated}
                    />

                    <CancelContpaq
                        setShowCancel={setShowCancel}
                        showCancel={showCancel}
                        historicalXML={historicalXML}
                        historicalPDF={historicalPDF}
                        documentsNumber={documentsNumber}
                        prefactureUrlXml={prefactureUrlXml}
                        prefactureUrl={prefactureUrl}
                        prefactureDetailID={prefactureDetailID}
                        setUpdateList={setUpdateList}
                        updateList={updateList} 
                        context={context}
                        period={period}
                        lastPeriod={lastPeriod}
                        isConsolidationPrefacture={isConsolidationPrefacture}
                    />

                    <UploadManually
                        uploadManually={uploadManually}
                        setUploadManually={setUploadManually}
                        documentsNumber={documentsNumber}
                        prefactureDetailID={prefactureDetailID}
                        idCompany={id_company}
                        setUpdateList={setUpdateList}
                        updateList={updateList} 
                    />

                      <Peview 
                        totalDual = {totalDual}
                        countInterns={allApprovedInterns.length}
                        showPreview={showPreview}
                        setShowPreview={setShowPreview}
                        isApproved={isApproved}
                        context={context}
                        isConsolidationPrefacture={isConsolidationPrefacture}
                        period={period} 
                        getFormatedDate={getFormatedDate}
                        lastPeriod={lastPeriod}
                        prefactureData={prefactureData}
                        setVerifyPreview = {setVerifyPreview}
                        verifyPreview = {verifyPreview}
                        setSendPrefactureAux={setSendPrefactureAux}
                        sendPrefactureAux={sendPrefactureAux}
                        agentName={agentName}
                        options={options}
                    />

                    <PreviewMD 
                        totalDual = {totalDual}
                        countInterns={allApprovedInterns.length}
                        showPreviewMD={showPreviewMD}
                        setShowPreviewMD={setShowPreviewMD}
                        isApproved={isApproved}
                        context={context}
                        isConsolidationPrefacture={isConsolidationPrefacture}
                        period={period} 
                        getFormatedDate={getFormatedDate}
                        lastPeriod={lastPeriod}
                        prefactureData={prefactureData}
                        setVerifyPreviewMD = {setVerifyPreviewMD}
                        verifyPreviewMD = {verifyPreviewMD}
                        setSendPrefactureAux={setSendPrefactureAux}
                        sendPrefactureAux={sendPrefactureAux}
                        agentName={agentName}
                        options={options}
                    />

                    <div className="interns-list-options float-right">
                        <p>Elementos por página:</p>
                        <DropdownList
                            data={[10, 20, 40, 60, 100, 200, 300]}
                            defaultValue={currentLimit}
                            onSelect={(e) => {
                                setCurrentLimit(e);
                                if(currentSearch && currentSearch != ""){
                                    getInterns(undefined, undefined, e, false, currentSearch);
                                }else{
                                    getInterns(undefined, undefined, e)
                                }
                            }}
                            containerClassName="limit-container"
                        />
                        <ReactPaginate
                            pageCount={1} 
                            containerClassName="pagination-container" 
                            previousLabel={<FontAwesomeIcon icon={faChevronLeft}/>} 
                            nextLabel={<FontAwesomeIcon icon={faChevronRight}/>}
                        />
                    </div>

                    <ApprovePrefacture 
                        totalDual = {totalDual}
                        countInterns={allApprovedInterns.length}
                        approvedInterns={approvedInterns} 
                        showApprovePrefacture={showApprovePrefecture} 
                        setShowApprovePrefacture={setShowApprovePrefecture} 
                        updateList={updateList} 
                        setUpdateList={setUpdateList} 
                        period={period}
                        prefactureData={prefactureData}
                        payDay={payDay}
                        payDayLimit={payDayLimit}
                        isSending={isSending}
                        setIsSending={setIsSending}
                        previusQMPrefacture={previusQMPrefacture}
                        previusQMPrefactureDetails={previusQMPrefactureDetails}                        
                        prefacturePaymentsAux={prefacturePaymentsAux}
                        agentName={agentName}
                        consolidationValidations={consolidationValidations}
                    />
                    <RollBackPrefacture 
                        isApproved={isApproved}
                        setIsApproved={setIsApproved}
                        prefactureId={prefactureId} 
                        showRollBackPrefacture={showRollBackPrefacture} 
                        setShowRollBackPrefacture={setShowRollBackPrefacture} 
                        updateList={updateList} 
                        setUpdateList={setUpdateList} 
                        period={period}
                        prefactureInterns={prefactureInterns}
                        setIsPrefactureDetail={setIsPrefactureDetail}
                        setPrefactureData={setPrefactureData}
                        setIsEditable={setIsEditable}
                        setIsSending={setIsSending}
                        isConsolidationPrefacture={isConsolidationPrefacture}
                        previusQMPrefacture={previusQMPrefacture}
                        previusQMPrefactureDetails={previusQMPrefactureDetails}
                        allPrefactureQMInterns={allPrefactureQMInterns}
                        canRollBackFirstQMPeriod={canRollBackFirstQMPeriod}
                        consolidationValidations={consolidationValidations}
                    />

                    <VideoPlayer
                        showVideoPlayer={showVideoPlayer}
                        setShowVideoPlayer={setShowVideoPlayer}
                    />

                    {isLoadingPrefacture && <LoadingModal isLoadingPrefacture={isLoadingPrefacture} />}
                </>
            }


            {currentTab == "verifyInternDocs" &&
                <VerifyInternDocs 
                    setCurrentTab={setCurrentTab} 
                    currentTab={currentTab} 
                    currentInternId={currentInternId} 
                    interns={interns} 
                    setUpdateList={setUpdateList} 
                    updateList={updateList}
                    updatedFromPrefacture={updatedFromPrefacture}
                    setContractToUpdateRenew={setContractToUpdateRenew}
                    setContractToUpdateZipCode={setContractToUpdateZipCode}
                />
            }

            {currentTab == "editContrat" &&
                <UpdateRenewContract 
                    setCurrentTab={setCurrentTab} 
                    context={context}
                    contractToUpdateRenew={contractToUpdateRenew}
                    contractToUpdateZipCode={contractToUpdateZipCode}
                />
            }
        </div>
    );
};